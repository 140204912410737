<template>
  <auth-container>
    <template #authForm>
      <p class="has-text-weight-semibold" style="margin-bottom: 1rem">
        {{ $t('You are now logged out.') }}
      </p>
      <strong>
        <a @click="goHome">{{ $t('Return home') }}</a>
      </strong>
    </template>
  </auth-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AuthContainer from './AuthContainer';

export default defineComponent({
  name: 'LogOutScreen',
  components: {
    AuthContainer,
  },
  methods: {
    goHome() {
      this.$router.replace('/');
    },
  },
});
</script>
